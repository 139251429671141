import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import { CardContainer, Div, Paginator } from '@driftt/tide-core';

import { downloadVideoReport, fetchTeam, fetchUserVideos } from 'api';
import LibraryNavigationHeader from 'components/libraryNavigationHeader';
import SunsetBanner from 'components/sunsetBanner';
import VideoLibraryTable from 'components/videoLibraryTable';
import { createCSVDownload } from 'utils';
import {
  DECREMENT_PAGE,
  INCREMENT_PAGE,
  RESET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_STRING,
  SET_SORT_DIRECTION,
  SET_SORT_KEY,
  SET_SOURCE_TYPE,
  SET_TEAM,
} from 'utils/actions';
import { isHowToVideosFolder } from 'utils/folderUtils';

import EmptyState from './EmptyState';
import ErrorState from './ErrorState';
import searchParametersReducer from './searchParametersReducer';
import VideoLibraryFilters from './VideoLibraryFilters';

import './styles.css';

const VideoLibraryPage = ({ sourceTypeParam, teamLibrary, folder, onFolderFetched }) => {
  const [videosLoading, setVideosLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isTeamResult, setIsTeamResult] = useState(teamLibrary);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(null);
  const [hadError, setHadError] = useState(false);
  const [searchParameters, dispatch] = useReducer(searchParametersReducer, {
    searchString: null,
    pageIndex: 0,
    startDate: null,
    endDate: null,
    sortKey: 'datetime_created',
    sortDirection: 'desc',
    pageSize: 10,
    sourceType: sourceTypeParam ? Number(sourceTypeParam) : null,
    team: teamLibrary,
    userFilter: 'all',
  });

  const [forceFetchId, setForceFetchId] = useState(0);
  const forceFetch = () => setForceFetchId((id) => id + 1);

  const {
    searchString,
    pageIndex,
    startDate,
    endDate,
    sortKey,
    sortDirection,
    pageSize,
    sourceType,
    team,
    userFilter,
  } = searchParameters;

  useEffect(() => {
    if (team && users.length === 0) {
      fetchTeam()
        .then((response) => {
          if (response) {
            setUsers(response);
          }
        })
        .catch((err) => {
          Error(err);
        });
    }
  }, [team, users.length]);

  useEffect(() => {
    const sourceType = sourceTypeParam ? Number(sourceTypeParam) : null;
    dispatch({ type: SET_SOURCE_TYPE, sourceType });
  }, [sourceTypeParam]);

  useEffect(() => {
    dispatch({ type: SET_TEAM, team: teamLibrary });
  }, [teamLibrary]);

  const inflightPromise = useRef(null);

  useEffect(() => {
    setVideosLoading(true);

    const promise = fetchUserVideos({
      team,
      sortKey,
      sortDescending: sortDirection === 'desc',
      pageSize,
      pageNum: pageIndex + 1,
      searchString,
      after: startDate && startDate.format('YYYY-MM-DD'),
      before: endDate && endDate.format('YYYY-MM-DD 23:59:59'),
      sourceType,
      user: userFilter === 'all' ? undefined : userFilter,
      folder,
    });

    inflightPromise.current = promise;

    promise
      .then((response) => {
        if (response && promise === inflightPromise.current) {
          setVideos(response.results);
          setCount(response.count);
          setFolders(response.folders);
          onFolderFetched(response.folder);
          setIsTeamResult(team);
          setHadError(false);
        }
      })
      .catch((err) => {
        if (pageIndex > 0 && err.response?.status === 404) {
          dispatch({ type: RESET_PAGE });
        } else {
          setVideos([]);
          setFolders([]);
          setHadError(true);
          Error(err);
        }
      })
      .finally(() => {
        setVideosLoading(false);
      });
  }, [
    team,
    sortKey,
    sortDirection,
    searchString,
    pageSize,
    pageIndex,
    startDate,
    endDate,
    sourceType,
    userFilter,
    forceFetchId,
    folder,
    onFolderFetched,
  ]);

  const handleSearchInputChange = debounce((searchString) => {
    dispatch({ type: SET_SEARCH_STRING, searchString });
  }, 200);

  const exportCsv = useCallback(async () => {
    try {
      const data = await downloadVideoReport(
        startDate && startDate.format('YYYY-MM-DD'),
        endDate && endDate.format('YYYY-MM-DD'),
        searchString,
      );

      createCSVDownload(data);
    } catch (e) {
      Error(e);
    }
  }, [startDate, endDate, searchString]);

  const no_filters_applied =
    !searchString &&
    startDate == null &&
    endDate == null &&
    sourceType == null &&
    folders.length === 0;

  return (
    <Div className="video-library-wrapper">
      <Div className="video-library-header">
        <LibraryNavigationHeader sourceType={sourceType} team={team} refreshTable={forceFetch} />
      </Div>
      <SunsetBanner />
      {no_filters_applied && count === 0 && !videosLoading && !hadError ? (
        <CardContainer className="video-library-body empty">
          <VideoLibraryFilters
            sourceType={sourceType}
            team={team}
            searchString={searchString}
            teamLibrary={teamLibrary}
            users={users}
            userFilter={userFilter}
            dispatch={dispatch}
            startDate={startDate}
            endDate={endDate}
            count={count}
            folder={folder}
            videosLoading={videosLoading}
            exportCsv={exportCsv}
            handleSearchInputChange={handleSearchInputChange}
          ></VideoLibraryFilters>
          <EmptyState
            hasNoVideos={true}
            isEmptyFolder={false}
            isFolder={false}
            isTeamResult={false}
          />
        </CardContainer>
      ) : (
        <CardContainer className="video-library-body">
          <VideoLibraryFilters
            sourceType={sourceType}
            team={team}
            searchString={searchString}
            teamLibrary={teamLibrary}
            users={users}
            userFilter={userFilter}
            dispatch={dispatch}
            startDate={startDate}
            endDate={endDate}
            count={count}
            folder={folder}
            videosLoading={videosLoading}
            exportCsv={exportCsv}
            handleSearchInputChange={handleSearchInputChange}
          ></VideoLibraryFilters>
          <VideoLibraryTable
            loading={videosLoading}
            videos={videos}
            folders={folders}
            users={users}
            isTeamResult={isTeamResult}
            sortKey={sortKey}
            sortDirection={sortDirection}
            setSortKey={(sortKey) => dispatch({ type: SET_SORT_KEY, sortKey })}
            setSortDirection={(sortDirection) =>
              dispatch({ type: SET_SORT_DIRECTION, sortDirection })
            }
            onDeleteVideo={forceFetch}
            isReadOnlyFolder={Boolean(folder?.readonly)}
            isHowToFolder={folder && isHowToVideosFolder(folder)}
          />
          {videos.length > 0 && (
            <Paginator
              position="static"
              index={pageIndex}
              size={pageSize}
              sizes={[10, 25, 50]}
              changeSize={(event) => {
                dispatch({ type: SET_PAGE_SIZE, pageSize: event.value });
              }}
              totalPages={Math.ceil(count / pageSize)}
              goForward={() => dispatch({ type: INCREMENT_PAGE })}
              goBackward={() => dispatch({ type: DECREMENT_PAGE })}
            />
          )}
          {count === 0 && !hadError && (
            <EmptyState
              searchString={searchString}
              startDate={startDate}
              endDate={endDate}
              isTeamResult={isTeamResult}
              isFolder={Boolean(folder)}
              isEmptyFolder={Boolean(folder) && folders.length === 0}
              sourceType={sourceType}
              userFilter={userFilter !== 'all' ? userFilter : undefined}
            />
          )}
          {hadError && <ErrorState />}
        </CardContainer>
      )}
    </Div>
  );
};

VideoLibraryPage.propTypes = {
  sourceTypeParam: PropTypes.string,
  teamLibrary: PropTypes.bool.isRequired,
  folder: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    parentFolder: PropTypes.number,
    privacyStatus: PropTypes.number,
    readonly: PropTypes.bool,
  }),
  onFolderFetched: PropTypes.func.isRequired,
};

export default VideoLibraryPage;
