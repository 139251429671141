import React, { useContext, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Cookies from 'js-cookie';
import qs from 'query-string';

import CustomDragLayer from 'components/customDragLayer';
import FolderContext from 'context/FolderContext';
import { UserContext } from 'context/UserContext';

import VideoLibraryPage from './VideoLibraryPage';

import '@driftt/react-dates/initialize';

import '@driftt/react-dates/lib/css/_datepicker.css';
import './styles.css';

const VideoLibraryApp = () => {
  const { user, userHasFeature, appLoading, createUrlPath, hasUnifiedSeatExperience } =
    useContext(UserContext);
  const { folder, refreshFolderSelection, handleFolderFetched } = useContext(FolderContext);
  const hasTeamLibrary = userHasFeature('HAS_TEAM_LIBRARY');

  const [initialLoad, setInitialLoad] = useState(true);

  const renderLibraryRoute = ({ location, match }) => {
    const onboardingSelectionChoice = Cookies.get('onboarding_type_selection');
    if (
      user &&
      !hasUnifiedSeatExperience &&
      !user.hasCompletedOnboarding &&
      user?.daysFromSignup < 100 &&
      !onboardingSelectionChoice
    ) {
      return <Redirect to={{ pathname: '/video/onboarding/' }} />;
    }

    const isTeamLibrary = match.url === '/video/team/';
    if (isTeamLibrary && !hasTeamLibrary) {
      return <Redirect to={{ pathname: '/video/' }} />;
    }

    const params = qs.parse(location.search);
    if (!initialLoad) {
      refreshFolderSelection();
    } else {
      setInitialLoad(false);
    }

    if (folder) {
      document.title = `Drift | ${folder?.parentFolder ? folder?.title : 'Videos'}`;
    }

    return (
      <VideoLibraryPage
        sourceTypeParam={params.source_type}
        teamLibrary={isTeamLibrary}
        folder={folder}
        onFolderFetched={handleFolderFetched}
      />
    );
  };

  return !appLoading ? (
    <>
      <CustomDragLayer />
      <Route exact path="/video/team/" render={renderLibraryRoute} />
      <Route exact path="/video/how-to/" render={renderLibraryRoute} />
      <Route exact path="/video/" render={renderLibraryRoute} />
      <Route
        exact
        path="/video/onboarding/"
        render={() => (window.location.href = createUrlPath('/video/onboarding/'))}
      />
    </>
  ) : null;
};

export default VideoLibraryApp;
